const GoogleIcon: React.FC = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M910.933333 473.6h-391.253333v116.48h277.76c-14.08 162.56-149.333333 232.106667-277.333333 232.106667C356.693333 822.186667 213.333333 693.333333 213.333333 512c0-174.933333 136.533333-310.186667 307.2-310.186667 131.84 0 209.066667 84.053333 209.066667 84.053334L810.666667 201.386667S706.56 85.333333 516.266667 85.333333C273.92 85.333333 86.613333 290.133333 86.613333 512c0 215.466667 176.213333 426.666667 436.053334 426.666667 228.266667 0 394.666667-156.586667 394.666666-387.84 0-49.066667-6.4-77.226667-6.4-77.226667z"
        fill="#dc4d28"
      />
    </svg>
  )
}

export default GoogleIcon
