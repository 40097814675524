const LinkedinIcon: React.FC = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      width={16}
      height={16}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.43 361.726h185.949v597.36H76.429v-597.36zM169.46 64.76c59.45 0 107.652 48.27 107.652 107.624 0 59.416-48.202 107.679-107.651 107.679-59.662 0-107.772-48.263-107.772-107.679C61.688 113.03 109.798 64.76 169.461 64.76M378.943 361.726h178.074v81.644h2.526c24.76-47.003 85.404-96.498 175.787-96.498 187.963 0 222.73 123.667 222.73 284.553v327.66h-185.6V668.653c0-69.336-1.374-158.46-96.56-158.46-96.684 0-111.423 75.456-111.423 153.333v295.56H378.943v-597.36z"
        fill="#006A9A"
      />
    </svg>
  )
}

export default LinkedinIcon
