import { signIn } from 'next-auth/react'
import { LogError } from '../../utils/helpers/sentryFn'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import LinkedinIcon from '../Icons/LinkedinIcon'
import GoogleIcon from '../Icons/GoogleIcon'

const LoginWithSocial: React.FC = () => {
  const handleGoogleLogin = async () => {
    try {
      await signIn('google', {
        redirect: false,
        callbackUrl: '/r',
      })
    } catch (error: any) {
      LogError({
        error,
        title: 'Google Login Error',
        extra: {
          component: 'LoginWithSocial',
          description: 'Error while trying to login with Google',
        },
      })
    }
  }

  const handleLinkedinLogin = async () => {
    try {
      await signIn('linkedin', {
        redirect: false,
        callbackUrl: '/r',
      })
    } catch (error: any) {
      LogError({
        error,
        title: 'Linkedin Login Error',
        extra: {
          component: 'LoginWithSocial',
          description: 'Error while trying to login with Linkedin',
        },
      })
    }
  }

  return (
    <Stack direction={{ xs: 'column', lg: 'row' }}>
      <Box
        component={'a'}
        sx={{
          marginRight: { xs: 0, lg: 4.5 },
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.75rem',
        }}
        href="#"
        className="theme-btn social-btn-two facebook-btn"
        onClick={handleLinkedinLogin}
      >
        <LinkedinIcon />
        Log In via Linkedin
      </Box>
      <Box
        component={'a'}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.75rem',
        }}
        href="#"
        onClick={handleGoogleLogin}
        className="theme-btn social-btn-two google-btn"
      >
        <GoogleIcon /> Log In via Gmail
      </Box>
    </Stack>
  )
}

export default LoginWithSocial
